export const onlyNumberWithMaxLen = (value, maxLength) => {
  if (value == null) return value;

  let svalue = value.toString();

  if (svalue.length > maxLength) {
    svalue = svalue.slice(0, maxLength);
  }

  return svalue.replace(/\D/g, "");
};
